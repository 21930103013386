@import "src/styles/prepend"; .link-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $layout-unit * 3;
  border: 1px solid $color-grey-200;
  border-radius: $layout-unit * 2;
  outline: 2px solid transparent;
  outline-offset: 2px;
  transition: background-color .3s ease-in-out, outline .3s ease-in-out;

  // .link-box.focus-visible
  &:global(.focus-visible) {
    outline-color: $color-blue-500;

    // .link-box.focus-visible .link-box__button-icon
    & .link-box__button-icon {
      transform: translateX(0);
      opacity: 1;
    }
  }

  // .link-box:hover
  &:hover {
    cursor: pointer;
    background-color: $color-grey-100;

    // .link-box:hover .link-box__button-icon
    & .link-box__button-icon {
      transform: translateX(0);
      opacity: 1;
    }
  }

  // .link-box__button-icon
  &__button-icon {
    opacity: 0;
    transform: translateX(-10px);
    transition: opacity .3s ease-in-out, transform .3s ease-in-out;
  }

  // .link-box__icon-and-text
  &__icon-and-text {
    display: flex;
    align-items: center;
  }

  // .link-box__icon-wrapper
  &__icon-wrapper {
    display: flex;
    margin-right: $layout-unit * 3;
  }

  // .link-box__text
  &__text {
    font-weight: $font-weight-semibold;
    margin-right: $layout-unit * 3;
  }

  // .link-box--completed
  &--completed {
    pointer-events: none;
  }
}
