@import "src/styles/prepend"; .products-card-content {
  position: static;

  // .products-card-content__grid-button
  &__grid-button {
    border-radius: $layout-unit * 2;
  }

  // .products-card-content__product-card
  &__product-card {
    height: 100%;
  }

  // .products-card-content__products
  &__products {
    display: grid;
    gap: $layout-unit * 3;
    width: 100%;
    grid-auto-rows: 1fr;
    grid-template-columns: 1fr;

    @include media-breakpoint-up(md) {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }

    @include media-breakpoint-up(xxl) {
      grid-template-columns: repeat(auto-fit, 1fr);
    }
  }
}
