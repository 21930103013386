@import "src/styles/prepend"; .menus-card-content {
  position: static;

  // .menus-card-content__grid-button
  &__grid-button {
    border-radius: $layout-unit;
  }

  // .menus-card-content__menus
  &__menus {
    display: grid;
    gap: $layout-unit * 3;
    width: 100%;
    grid-auto-rows: 1fr;
    grid-template-columns: repeat(auto-fit, 135px);
  }

  // .menus-card-content__menu-image-wrapper
  &__menu-image-wrapper {
    position: relative;
    height: 80px;
    width: 100%;
    border-radius: $layout-unit;
    border: 1px solid $color-blue-500;
    background-color: $color-grey-100;
    overflow: hidden;
  }
}
