@import "src/styles/prepend"; .card {
  border-radius: $layout-unit * 3;
  background-color: $color-white;
  box-shadow: 0px 18px 28px rgba($color-blue-500, 0.05);
  padding: $layout-unit * 8;
  overflow: hidden;

  // .card__children
  &__children {
    height: 100%;

    // .card__with-margin-top
    &--with-margin-top {
      margin-top: $layout-unit * 5;
    }
  }

  // .card__description
  &__description {
    color: $color-grey-500;
    margin-top: $layout-unit * 2;
    margin-bottom: 0;
  }

  // .card__header
  &__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  // .card__number
  &__number {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: $layout-unit * 5;
    width: $layout-unit * 5;
    flex-shrink: 0;
    color: $color-white;
    font-size: $font-size-extra-small;
    line-height: $font-line-height-extra-small;
    font-weight: $font-weight-semibold;
    border-radius: 50%;
    background-color: $color-blue-500;
    // We need a margin-top of 2px so that the number is aligned with the text (that has 24px of line height)
    margin: 2px $layout-unit * 2 0 0;
  }

  // .card__number-and-title
  &__number-and-title {
    display: flex;
    align-items: flex-start;
  }

  // .card__title
  &__title {
    // .card__title--small
    &--small {
      font-size: $font-size-large;
      line-height: $font-line-height-large;
      font-weight: $font-weight-semibold;
    }
  }

  // .card--border
  &--border {
    border: 1px solid $color-grey-200;
    box-shadow: none;
  }

  // .card--padding-none
  &--padding-none {
    padding: 0;
  }

  // .card--padding-small
  &--padding-small {
    padding: $layout-unit * 5;
  }
}
