@import "src/styles/prepend"; .product-card {
  display: grid;
  grid-template-columns: minmax(0, 1fr) 1fr;
  gap: $layout-unit * 3;
  padding: $layout-unit * 2;
  border-radius: $layout-unit * 2;
  background-color: $color-white;
  border: 1px solid $color-grey-200;
  box-shadow: 0px 0px 16px rgba(9, 30, 66, 0.03);
  outline: 2px solid transparent;
  outline-offset: 2px;
  transition: background-color .3s ease-in-out, border .3s ease-in-out, outline .3s ease-in-out;

  // If there's only one child (no image), it should take up the full width
  // .product-card > :only-child
  > :only-child {
    grid-column: span 2;
  }

  // .product-card__allergen-icon
  &__allergen-icon {
    color: $color-grey-500;
    height: $layout-unit * 4;
    width: $layout-unit * 4;
  }

  // .product-card__allergens-wrapper
  &__allergens-wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: $layout-unit * 2;
  }

  // .product-card__description
  &__description {
    display: -webkit-box;
    font-size: $font-size-extra-small;
    line-height: $font-line-height-extra-small;
    color: $color-grey-500;
    margin-top: $layout-unit;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: 100%;

    @include media-breakpoint-up(md) {
      font-size: $font-size-small;
      line-height: $font-line-height-small;
    }
  }

  // .product-card__featured-icon
  &__featured-icon {
    color: $color-primary-500;
  }

  // .product-card__icon
  &__icon {
    height: $layout-unit * 4;
    width: $layout-unit * 4;
  }

  // .product-card__icons
  &__icons {
    position: absolute;
    top: $layout-unit * 1.5;
    right: $layout-unit * 2;
    display: flex;
    align-items: center;
    gap: $layout-unit;
    z-index: 1;
  }

  // .product-card__icon-wrapper
  &__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: $layout-unit $layout-unit * 1.5;
    border-radius: $layout-unit * 3;
    background-color: $color-white;
  }

  // .product-card__image-wrapper
  &__image-wrapper {
    position: relative;
    min-height: 96px;
    height: 100%;
    width: 100%;
    border-radius: $layout-unit * 2;
    overflow: hidden;
  }

  // .product-card__info
  &__info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: $layout-unit * 5;
    justify-content: space-between;
    width: 100%;
  }

  // .product-card__people-serve-number
  &__people-serve-number {
    font-size: $font-size-extra-small;
    line-height: $font-line-height-extra-small;
    margin-left: $layout-unit;
  }

  // .product-card__price
  &__price {
    color: $color-primary-500;
    color: var(--restaurant-color);
    font-size: $font-size-small;
    line-height: $font-line-height-small;
    font-weight: $font-weight-semibold;

    @include media-breakpoint-up(md) {
      font-size: $font-size-medium;
      line-height: $font-line-height-medium;
    }

    // .product-card__price--small
    &--small {
      @include media-breakpoint-up(md) {
        font-size: $font-size-small;
        line-height: $font-line-height-small;
      }
    }
  }

  // .product-card__price-and-allergens
  &__price-and-allergens {
    display: flex;
    flex-direction: column;
    gap: $layout-unit * 2;
  }

  // .product-card__title
  &__title {
    display: -webkit-box;
    font-size: $font-size-small;
    line-height: $font-line-height-small;
    font-weight: $font-weight-semibold;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @include media-breakpoint-up(md) {
      font-size: $font-size-medium;
      line-height: $font-line-height-medium;
    }

    // .product-card__title--small
    &--small {
      @include media-breakpoint-up(md) {
        font-size: $font-size-small;
        line-height: $font-line-height-small;
      }
    }
  }

  // .product-card--is-interactive
  &--is-interactive {
    // .product-card--is-interactive.focus-visible
    &:global(.focus-visible) {
      border-radius: $layout-unit * 2;
      outline-color: $color-blue-500;
    }
  
    // .product-card--is-interactive:hover
    &:hover {
      cursor: pointer;
      border: 1px solid $color-grey-200;
      background-color: $color-grey-100;
    }
  }

  // .product-card__padding-size-medium
  &--padding-size-medium {
    padding: $layout-unit * 3;
  }
}
