@import "src/styles/prepend"; .dashboard {
  padding: $layout-unit * 3 0 $layout-unit * 26 0;
  overflow: auto;
  position: relative;
  max-height: 100vh;
  overflow-y: auto;

  @include media-breakpoint-up(xl) {
    padding: $layout-unit * 20 0;
  }

  // .dashboard--locked
  &--locked {
    pointer-events: none;
  }

  // .dashboard__card
  &__card {
    padding: $layout-unit * 5;
  
    @include media-breakpoint-up(xl) {
      padding: $layout-unit * 8;
    }
  }

  // .dashboard__content-grid
  &__content-grid {
    display: grid;
    grid-auto-columns: 1fr;
    gap: $layout-unit * 8;
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 $layout-unit * 5;
    
    @include media-breakpoint-up(xl) {
      grid-auto-columns: 1.3fr 1fr;
      padding: 0 $layout-unit * 15;
    }

    @include media-breakpoint-up(xxl) {
      grid-auto-columns: 1.7fr 1fr;
    }
  }

  // .dashboard__content-grid-left-column
  &__content-grid-left-column {
    display: grid;
    gap: $layout-unit * 8;
    height: fit-content;

    @include media-breakpoint-up(xl) {
      grid-column: 1;
    }
  }

  // .dashboard__content-grid-right-column
  &__content-grid-right-column {
    display: grid;
    gap: $layout-unit * 8;
    height: fit-content;

    @include media-breakpoint-up(xl) {
      grid-column: 2;
    }
  }

  // .dashboard__loader-wrapper
  &__loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  // .dashboard__menu-and-products-cards
  &__menu-and-products-cards {
    display: grid;
    gap: $layout-unit * 2;
  }

  // .dashboard__todo-items
  &__todo-items {
    display: grid;
    gap: $layout-unit * 2;
  }
}
