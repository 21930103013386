@import "src/styles/prepend"; .tooltip {
  position: static;

  // .tooltip__arrow
  &__arrow {
    fill: $color-blue-500;
  }

  // .tooltip__content
  &__content {
    display: flex;
    gap: $layout-unit * 2;
    padding: $layout-unit * 2 $layout-unit * 3;
    border-radius: $layout-unit * 2.5;
    background-color: $color-blue-500;
  }

  // .tooltip__content-wrapper
  &__content-wrapper {
    // Above everything and at the same level as the toast
    z-index: 3;
  }

  // .tooltip__text
  &__text {
    font-size: $font-size-extra-small;
    line-height: $font-line-height-extra-small;
    color: $color-white;
  }
}
