@import "src/styles/prepend"; .product-modal {
  display: block;

  // .product-modal__allergen-icon
  &__allergen-icon {
    color: $color-grey-500;
    height: $layout-unit * 4;
    width: $layout-unit * 4;
  }

  // .product-modal__allergens-wrapper
  &__allergens-wrapper {
    display: flex;
    gap: $layout-unit * 2;
  }

  // .product-modal__close-button
  &__close-button {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: $layout-unit * 5;
    right: $layout-unit * 5;
    color: $color-grey-500;
    height: $layout-unit * 8;
    width: $layout-unit * 8;
    padding: $layout-unit;
    border-radius: $layout-unit * 2;
    background-color: $color-white;
    outline: 2px solid transparent;
    outline-offset: 2px;

    @include media-breakpoint-up(md) {
      top: $layout-unit * 7;
      right: $layout-unit * 7;
    }

    &:global(.focus-visible) {
      outline-color: $color-primary-500;
    }
  }

  // .product-modal__description
  &__description {
    color: $color-grey-500;
    margin-top: $layout-unit * 2;
  }

  // .product-modal__divider-line
  &__divider-line {
    height: 1px;
    background-color: $color-grey-300;
    border: none;
    width: 100%;
    margin-top: $layout-unit * 3;
    margin-bottom: $layout-unit * 3;
  }

  // .product-modal__image-wrapper
  &__image-wrapper {
    position: relative;
    height: 186px;
    width: 100%;
    border-radius: $layout-unit * 2;
    overflow: hidden;
  }

  // .product-modal__ingredients
  &__ingredients {
    color: $color-grey-500;

    // .product-modal__ingredients--no-margin-top
    &--no-margin-top {
      margin-top: 0;
    }
  }

  // .product-modal__ingredients-title
  &__ingredients-title {
    font-size: $font-size-medium;
    line-height: $font-line-height-medium;
    font-weight: $font-weight-semibold;
  }

  // .product-modal__price
  &__price {
    color: $color-primary-500;
    color: var(--restaurant-color);
    font-size: $font-size-small;
    line-height: $font-line-height-small;
    font-weight: $font-weight-semibold;

    @include media-breakpoint-up(md) {
      font-size: $font-size-medium;
      line-height: $font-line-height-medium;
    }
  }

  // .product-modal__price-and-allergens
  &__price-and-allergens {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $layout-unit * 5;
  }

  // .product-modal__title
  &__title {
    // .product-modal__title--with-margin-right
    &--with-margin-right {
      margin-right: $layout-unit * 8;
    }

    // .product-modal__title--with-margin-top
    &--with-margin-top {
      margin-top: $layout-unit * 5;
    }
  }
}
